/// <reference path="./next-auth.d.ts" />
'use client';
import * as React from 'react';
import { UserInformation } from '@dreamplan/types';
import { getSession, signOut } from 'next-auth/react';
import { Role } from '.';

type TUser = UserInformation & { uid: string; role?: Role };
type UserAuthenticationStatus =
  | {
      type: 'signed_in';
      data: TUser;
    }
  | {
      type: 'loading';
    }
  | {
      type: 'unsigned';
    };

type TAuthenticationContext = {
  user: UserAuthenticationStatus;
  fetchUserData: () => Promise<TUser | null>;
  onEditUser: (cred: Partial<{ email: string; name: string }>) => Promise<void>;
};

const AuthenticationContext = React.createContext<TAuthenticationContext>({
  user: {
    type: 'loading',
  },
  fetchUserData: () => Promise.resolve(null),
  onEditUser: () => Promise.resolve(),
});

export const useAuth = () => React.useContext(AuthenticationContext);

export const AuthenticationContextProvider: React.FC<{
  init?: UserAuthenticationStatus;
  children: React.ReactNode;
  onGetProfile: () => Promise<UserInformation>;
  onEditUser: (cred: Partial<{ email: string; name: string }>) => Promise<void>;
}> = ({ children, onGetProfile, onEditUser, init }) => {
  const [user, setUser] = React.useState<UserAuthenticationStatus>(init ?? { type: 'loading' });

  const fetchUserData = async () => {
    const session = await getSession();
    const localUser = session?.user;

    if (localUser) {
      setUser({ type: 'loading' });
      const userDetails = await onGetProfile();

      let user: TUser | null = null;
      if (userDetails != null) {
        user = {
          ...userDetails,
          guest: userDetails.guest || (session as any).user.isGuestUser, // extra-jwt params handling
          role: session.user.role,
        };
      } else {
        await signOut();
      }
      setUser({ type: 'signed_in', data: { ...user, uid: localUser.id } });

      return user;
    }

    setUser({ type: 'unsigned' });

    return null;
  };

  React.useEffect(() => {
    if (user.type === 'loading') {
      fetchUserData();
    }
  }, []);

  const memoizedValue = React.useMemo(
    () => ({
      user,
      fetchUserData,
      onEditUser,
    }),
    [user, onEditUser],
  );

  return (
    <AuthenticationContext.Provider value={memoizedValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};
