'use client';

import * as React from 'react';
import { LiteralUnion, AnalyticsEvents } from '@dreamplan/types';
import { useFirstInteraction } from '@dreamplan/ui';
import { AnalyticsInstance } from 'analytics';
import { usePathname } from 'next/navigation';
import { isAnalyticsEnabled } from './utils';
import { useAuth } from '@dreamplan/shared-ui/client/auth';

export type TOverloadTrack<T extends Record<string, any> = AnalyticsEvents> = <
  K extends keyof T & string,
>(
  eventName: LiteralUnion<K>,
  payload: T[K],
  options?: any,
  callback?: (...params: any[]) => any,
  lazyInitialize?: boolean,
) => Promise<any>;

type TAnalyticsContext<T extends Record<string, any>> = Omit<AnalyticsInstance, 'track'> & {
  track: TOverloadTrack<T>;
};

// Overload track event with our strongly typed closure implementation
const AnalyticsContext = React.createContext<TAnalyticsContext<any>>({} as any);

export const useAnalytics = <T extends Record<string, any> = AnalyticsEvents>() => {
  return React.useContext<TAnalyticsContext<T>>(AnalyticsContext);
};

export function AnalyticsContextProvider({
  children,
  enable = true,
}: {
  children: React.ReactNode;
  enable?: boolean;
}) {
  const { user } = useAuth();
  const interaction = useFirstInteraction();
  const [analytics, setAnalytics] = React.useState<AnalyticsInstance | null>(null);
  const [isEnabled, setEnabled] = React.useState<boolean | null>(null);
  const preInitializationQueue = React.useRef<{ eventName: string; payload: any; options: any }[]>(
    [],
  );
  const pathname = usePathname();

  const clearQueue = (_analytics: AnalyticsInstance) => {
    if (preInitializationQueue.current.length > 0) {
      preInitializationQueue.current.forEach((event) => {
        _analytics.track(event.eventName, event.payload, event.options);
      });
      preInitializationQueue.current = [];
    }
  };

  const updateUserToken = async (
    _analytics: AnalyticsInstance,
    details: {
      token: string | null;
      email?: string | null;
      firstName?: string;
      lastName?: string;
      isGuestUser: boolean;
    },
  ) => {
    return new Promise((resolve) => {
      const { token, email, firstName, lastName, isGuestUser } = details;
      const userTraits = {
        email: email,
        firstName: firstName || '',
        lastName: lastName || '',
        isGuestUser: isGuestUser,
      };

      if (token != null) {
        _analytics.identify(token, userTraits, {}, () => resolve(true));
      } else {
        resolve(true);
      }
    });
  };

  const identifyUser = async (_analytics: AnalyticsInstance) => {
    // global user data is ready, otherwise sync. useEffect will trigger on user state change
    if (user.type === 'signed_in') {
      const { data } = user;
      const firstName = data?.name?.split(' ')[0] || '';
      const lastName = data?.name?.split(' ')[1] || '';
      await updateUserToken(_analytics, {
        token: data.uid,
        email: data.email,
        firstName: firstName,
        lastName: lastName,
        isGuestUser: data.guest || false,
      });
    }
  };

  const initAnalytics = () => {
    import('./analytics').then((mod) => {
      if (analytics == null) {
        const _analytics = mod.default;
        identifyUser(_analytics).then(() => {
          // User must be identified before tracking events are dispatched
          clearQueue(_analytics);
        });
        setAnalytics(_analytics);
      }
    });
  };

  const track: TOverloadTrack = React.useCallback(
    (eventName, payload, options, callback, lazyInitialize = true) => {
      // terminate track if analytics is disabled
      if (!enable) {
        return Promise.resolve();
      }

      if (analytics != null) {
        clearQueue(analytics);
        return analytics.track(eventName, payload, options, callback);
      } else if (enable) {
        preInitializationQueue.current.push({
          eventName: eventName,
          payload,
          options,
        });
      }

      if (!lazyInitialize && isEnabled) {
        initAnalytics();
      }
      return Promise.resolve();
    },
    [analytics, isEnabled],
  );

  React.useEffect(() => {
    // Always runs after first interaction
    const isAdminPrinting = user.type === 'signed_in' && user.data.role === 'dashboard';
    if (interaction && enable && !isAdminPrinting) {
      isAnalyticsEnabled().then((response) => {
        setEnabled(response);
        if (response) {
          initAnalytics();
        }
      });
    }
  }, [interaction, enable]);

  React.useEffect(() => {
    if (analytics != null) {
      /**
       * Sync. user auth state with analytics trackers
       */
      if (user.type === 'signed_in') {
        const { data } = user;
        if (data != null) {
          const firstName = data?.name?.split(' ')[0] || '';
          const lastName = data?.name?.split(' ')[1] || '';
          updateUserToken(analytics, {
            token: data.uid,
            email: data.email,
            firstName: firstName,
            lastName: lastName,
            isGuestUser: data.guest || false,
          });
        }
      }
    }
  }, [analytics, user]);

  React.useEffect(() => {
    analytics?.page();
  }, [analytics, pathname]);

  const contextValue = React.useMemo(
    () =>
      ({
        ...analytics,
        track,
      } as TAnalyticsContext<AnalyticsEvents>),
    [analytics, track],
  );

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
}
